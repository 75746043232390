import { Box } from '@chakra-ui/react'
import { GreyWhite, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { MagicTable, MagicTableCell } from '@sequencehq/tables'
import { UTCDate } from '@date-fns/utc'
import { AccountingPeriod, useJournalReportsData } from './useJournalReportData'

import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import EmptyState from 'components/Loading/EmptyState'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'

export const JournalReportsTable = () => {
  const { magicTableLoader } = useJournalReportsData()
  const { currency } = useSelectedCurrency()

  return (
    <Box backgroundColor={GreyWhite} height="100%">
      <MagicTable<
        {
          model: AccountingPeriod
          controlFilters: undefined
        },
        string
      >
        entityNamePlural="Journal Entries"
        //@ts-expect-error - This is a highly coupled and weird interface, we just gotta duck type and hope
        useLazyQuery={magicTableLoader}
        rowPath={accountingPeriod =>
          `/revenue-recognition/journal-reports/report-detail?startDate=${accountingPeriod.dates.start}&endDate=${accountingPeriod.dates.endInclusive}&currency=${currency}`
        }
        emptyState={() => (
          <EmptyState
            emptyContent={{
              title: 'No accounting periods found',
              description:
                'Start using Sequence to see accounting periods listed here',
              docLink:
                'https://docs.sequencehq.com/revenue-recognition/recognising-pre-paid-revenue',
              linkCopy: 'Learn more'
            }}
          />
        )}
        columns={[
          {
            id: 'label',
            accessorFn: ({ dates }) => ({
              dates,
              label: formatInTimeZone(
                new UTCDate(dates.start),
                'UTC',
                'MMMM yyyy'
              )
            }),
            header: 'Journal report',
            cell: cellValue => {
              const { label, dates } = cellValue.getValue<{
                label: string
                dates: {
                  start: string
                  endInclusive: string
                }
              }>()
              return (
                <MagicTableCell
                  textColor={IndigoIndigo50}
                  text={label}
                  data-testid={`revrec.journalReport.${dates.start}`}
                />
              )
            }
          },
          {
            id: 'period',
            accessorFn: ({ dates }) =>
              `${formatInTimeZone(
                new UTCDate(dates.start),
                'UTC',
                'd MMM'
              )} to ${formatInTimeZone(
                new UTCDate(dates.endInclusive),
                'UTC',
                'd MMM'
              )}`,
            header: 'Period',
            cell: cellValue => {
              const formattedDate = cellValue.getValue<string>()
              return <MagicTableCell text={formattedDate} />
            }
          }
        ]}
      />
    </Box>
  )
}
