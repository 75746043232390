import { Card, InputSelectControl } from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { Lato13Regular, Lato14Bold } from '@sequencehq/design-tokens'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { AvalaraCompanies } from 'Integrations/domain'

interface Props {
  avalaraCompanies?: AvalaraCompanies
  enabledAvalaraCompany?: string
  setAvalaraCompany: (value: string) => void
}

const AvalaraCompanyCard = ({
  avalaraCompanies: externalAvalaraCompanies,
  enabledAvalaraCompany,
  setAvalaraCompany
}: Props) => {
  const isFirstTimeSetup = !enabledAvalaraCompany

  const { data } = useQuery(
    dashboardv99990101Client.getAllAvalaraCompanies,
    undefined,
    {
      enabled: !isFirstTimeSetup,
      select: response => response?.items ?? []
    }
  )

  const isSelectDisabled = isFirstTimeSetup && !externalAvalaraCompanies

  const avalaraCompanies = externalAvalaraCompanies || data || []

  const avalaraCompaniesOptions = avalaraCompanies.map(company => ({
    label: company.companyName,
    value: company.id
  }))

  return (
    <Card>
      <Box>
        <Box {...Lato14Bold} mb={2}>
          Avalara company
        </Box>
        <Box {...Lato13Regular} mb={2}>
          Link to the corresponding company in Avalara for this merchant.
        </Box>
        <Flex>
          <InputSelectControl
            data-testid="avalaraCompany.select"
            options={avalaraCompaniesOptions}
            initialValue={enabledAvalaraCompany}
            onChange={setAvalaraCompany}
            disabled={isSelectDisabled}
            placeholder="Select a company..."
          />
        </Flex>
      </Box>
    </Card>
  )
}

export default AvalaraCompanyCard
